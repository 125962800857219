import { Secp256k1KeyIdentity } from "@dfinity/identity-secp256k1";
import { Principal } from "@dfinity/principal";
import { fromHexString } from "@dfinity/candid/lib/cjs/utils/buffer";
//import { createActor } from "../ic/icloka";
import { createActor as createActorCKBTC } from "../IC/icLedgerCkbtc";
import { createActor as createMinter } from "../IC/icLedgerCkbtcMinter";

export const getUserIdentity = (privKey) => {
  try {
    const userIdentity = Secp256k1KeyIdentity.fromSecretKey(
      fromHexString(privKey)
    );

    return userIdentity;
  } catch (error) {
    return null;
  }
};

export const getUserPrincipal = (privKey) => {
  try {
    const userIdentity = Secp256k1KeyIdentity.fromSecretKey(
      fromHexString(privKey)
    );

    return userIdentity.getPrincipal();
  } catch (error) {
    return null;
  }
};

export const getUserPrincipalFromText = (walletAddress) => {
  try {
    return Principal.fromText(walletAddress);
  } catch (error) {
    return null;
  }
};

export const ckBTCAgentCreation = (privKey) => {
  try {
    const userIdentity = getUserIdentity(privKey);

    const userckBTCIdentity = createActorCKBTC("mxzaz-hqaaa-aaaar-qaada-cai", {
      identity: userIdentity,
    });

    return userckBTCIdentity;
  } catch (error) {
    return null;
  }
};

export const ckBTCMinterAgentCreation = (privKey) => {
  try {
    const userIdentity = getUserIdentity(privKey);

    const userckBTCIdentity = createMinter("mqygn-kiaaa-aaaar-qaadq-cai", {
      identity: userIdentity,
    });

    return userckBTCIdentity;
  } catch (error) {
    return null;
  }
};
