import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LaserEyesProvider, MAINNET } from "@omnisat/lasereyes";
// Import your components here
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Roshambo from "./pages/Roshambo";
import MetaDescription from "./components/MetaDescription";

function App() {
  return (
    <LaserEyesProvider config={{ network: MAINNET }}>
      <Router>
        <MetaDescription />
        <div className="App">
          <ToastContainer position="top-center" theme="colored" className="toast-position" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />

            <Route path="/roshambo" element={<Roshambo />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </LaserEyesProvider>
  );
}

export default App;
