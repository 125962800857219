import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { Principal } from "@dfinity/principal";
import axios from "axios";

import { verify } from "bitcoinjs-message";
import { useLaserEyes, UNISAT, XVERSE } from "@omnisat/lasereyes";
import {
  isModalOpenAtom,
  // loginInstanceAtom,
  //canisterActorAtom,
  //userDataAtom,
  //gameDataAtom,
  walletAddressAtom,
  icpAgentAtom,
  eyesLedgerAtom,
  //setCurrentEmailAtom,
  setWalletAliasAtom,
  isLoggedInAtom,
  spinActorAtom,
  roshamboActorAtom,
  roshamboEyesAtom,
  coreAtom,
  minterAtom,
} from "../store/Atoms";
import PropTypes from "prop-types";
import { getUserPrincipal } from "../service/icdragoncanister";
import { eyesCreation } from "../service/eyesledgercanister";
import {
  ckBTCAgentCreation as icpAgent,
  ckBTCMinterAgentCreation as minterAgent,
} from "../service/ckbtc";
import { actorCreationSpin } from "../service/spincanister";
import { toast } from "react-toastify";
import { actorCreationRoshambo } from "../service/roshambocanister";
import { actorCreationRoshambo as createRoshamboEyes } from "../service/roshamboeyes";
import { coreActorCreation } from "../service/core";
import CryptoJS from "crypto-js";

ConnectModal.propTypes = {
  selected: PropTypes.string.isRequired,
};

export default function ConnectModal({ selected }) {
  const [isModalOpen, setModalOpen] = useAtom(isModalOpenAtom);
  //const [loginInstance] = useAtom(loginInstanceAtom);
  const [walletAddress] = useAtom(walletAddressAtom);
  const setIsLoggedIn = useSetAtom(isLoggedInAtom);
  //const setCanisterActor = useSetAtom(canisterActorAtom);
  //const setUserData = useSetAtom(userDataAtom);
  //const setGameData = useSetAtom(gameDataAtom);
  const setWalletAddress = useSetAtom(walletAddressAtom);
  const setICPAgent = useSetAtom(icpAgentAtom);
  const setEyesLedger = useSetAtom(eyesLedgerAtom);
  const setMinterAtom = useSetAtom(minterAtom);
  //const setCurrentEmail = useSetAtom(setCurrentEmailAtom);
  const setWalletAlias = useSetAtom(setWalletAliasAtom);
  const setSpinActor = useSetAtom(spinActorAtom);
  const setRoshamboActor = useSetAtom(roshamboActorAtom);
  const setCoreActor = useSetAtom(coreAtom);
  const setRosamboEyesAgent = useSetAtom(roshamboEyesAtom);
  const [connectProgress, setConnectProgress] = useState("");

  const [loading, setLoading] = useState(false);
  const [signat, setSignat] = useState("");
  const { connect, signMessage, paymentAddress, address, connected } =
    useLaserEyes();
  const closeModal = () => setModalOpen(false);

  async function sign() {
    const message = "Sign to connect to Dragon Eyes";

    var privKeyA = 0;
    try {
      privKeyA = await signMessage(message);
      //console.log(privKeyA, "<<<<<<<<<< current sign");
      setConnectProgress("verifying Signature");
      var sgn = encodeURIComponent(privKeyA);
      var url =
        "https://api.dragoneyes.xyz/verifySign?addr=" +
        address +
        "&sign=" +
        sgn;
      var aax = await axios.get(url);
      privKeyA = aax.data;
      if (verify) {
        //
      }
      if (privKeyA.success) {
        privKeyA = privKeyA.success;
        //console.log(privKeyA, "<<<<<<<<<< key");
      } else {
        privKeyA = false;
      }
      setSignat(privKeyA);
      var isValid = false;

      // isValid = verify(message.toString(), address.toString(), privKeyA);
      if (isValid) {
        //
      }

      //console.log("Is the signature valid?", isValid);
      // console.log(privKeyA, "<<<<<<<<pr");
      await handleLogin(privKeyA);
      //await handleLogin();
    } catch (e) {
      setConnectProgress(e);
    }
  }

  async function handleConnect() {
    if (loading) return;
    setSignat(false);
    setLoading(true);
    if (walletAddress) return;
    setConnectProgress("connecting to wallet...");
    var use = UNISAT;
    if (selected == 1) use = XVERSE;
    try {
      await connect(use);
      setConnectProgress("wallet connected");
      setConnectProgress("awaiting signature");
      //console.log(address, "<<<<<<<< addrss");
    } catch (e) {
      console.log(e, "Errrr conn");
    }
  }

  const handleLogin = async (privKeyA) => {
    setLoading(true);
    // var stats = "";
    try {
      //var a = handleConnect();
      //setWalletAddress(a);

      setConnectProgress("accessing ICP via Bitcoin");

      //var privKeyA = signat;

      //const privKeyA = await handleConnect();
      //console.log(privKeyA, "<<<<<<<<<<a");
      if (!privKeyA) throw new Error("failed login");
      if (privKeyA == "") throw new Error("failed login - signature issue");
      var privKey = CryptoJS.SHA256(privKeyA)
        .toString(CryptoJS.enc.Hex)
        .slice(0, 64);
      //console.log(privKey, "<<<<<<<<<<a");

      //setCurrentEmail(loginInstance.getUserInfo().email);

      //const diceAgent = actorCreation(privKey);
      const icpAgent_ = icpAgent(privKey);
      const minterAgent_ = minterAgent(privKey);
      const eyes_ = eyesCreation(privKey);
      const spinWheel_ = actorCreationSpin(privKey);
      const roshambo = actorCreationRoshambo(privKey);
      const coreActor_ = coreActorCreation(privKey);
      const roshamboEyesAgent = createRoshamboEyes(privKey);

      const principalString_ = getUserPrincipal(privKey).toString();
      setRosamboEyesAgent(roshamboEyesAgent);
      //setCanisterActor(diceAgent);
      setICPAgent(icpAgent_);
      setEyesLedger(eyes_);
      setSpinActor(spinWheel_);
      setRoshamboActor(roshambo);
      setCoreActor(coreActor_);
      setMinterAtom(minterAgent_);
      setConnectProgress("fetching game data...");
      /*const [user_, game_] = await Promise.all([
        diceAgent.getUserData(),
        diceAgent.getCurrentGame(),
      ]); */
      setConnectProgress("fetching ICP-BTC wallet...");

      const btc_address = await minterAgent_.get_btc_address({
        owner: [Principal.fromText(principalString_)],
        subaccount: [],
      });
      //console.log(btc_address, "<<<<<bt");

      //setUserData(user_);
      //setGameData(game_);
      setWalletAddress(principalString_);
      setWalletAlias(btc_address);
      setIsLoggedIn(true);

      setModalOpen(false);
      closeModal();
    } catch (err) {
      closeModal();
      toast.error("Failed to connect to Bitcoin. Please try again.");
      console.log(err, "<<<<<<er");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log(isModalOpen, "<<<<<modol");
    if (isModalOpen) {
      console.log("connecting");
      handleConnect();
    }
    //handleConnect();
  }, [isModalOpen]);

  useEffect(() => {
    //console.log(isModalOpen, "<<<<<modol");
    if (paymentAddress && connectProgress == "wallet connected") {
      //sign();
    }

    //handleConnect();
  }, [connectProgress]);

  useEffect(() => {
    //console.log(connected, "<<<<<con");
    //console.log(signat, "<<<<<sg");
    //console.log(isModalOpen, "<<<<<modol");
    if (signat && connected) {
      //
    }

    //handleConnect();
  }, [signat]);

  return (
    isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
        <div className="bg-white rounded-lg shadow-lg w-96 p-6 relative">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            onClick={closeModal}
          >
            &times;
          </button>
          <h3 className="text-xl font-semibold mb-4 text-center">
            {!walletAddress
              ? "Sign Bitcoin Wallet to continue"
              : "Welcome to Dragon Eyes!"}
          </h3>
          <div className="text-center">
            <div>
              {loading ? (
                connectProgress == "awaiting signature" ? (
                  <button
                    onClick={sign}
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-600 transition duration-200"
                  >
                    click here to sign
                  </button>
                ) : (
                  connectProgress
                )
              ) : (
                "Connect with Bitcoin Wallet"
              )}
            </div>

            <p className="mt-4 text-sm text-gray-600"></p>
          </div>
        </div>
      </div>
    )
  );
}
