import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function MetaDescription() {
  const location = useLocation();

  useEffect(() => {
    const metaDescriptions = {
      "/": "Fair & Fun Onchain Games | Play Dice, Spin, or Rock Paper Scissor and win massive rewards!",
      "/about": "About page description",
      "/roshambo": "Roshambo | Play Rock Paper Scissor Game and double your BTC!",
      "*": "Page Not Found",
    };

    const description = metaDescriptions[location.pathname] || "Default description";

    // Update standard meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", description);

    // Update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute("content", description);

    // Update Twitter description
    let twitterDescription = document.querySelector('meta[name="twitter:description"]');
    if (!twitterDescription) {
      twitterDescription = document.createElement("meta");
      twitterDescription.setAttribute("name", "twitter:description");
      document.head.appendChild(twitterDescription);
    }
    twitterDescription.setAttribute("content", description);
  }, [location]);

  return null;
}

export default MetaDescription;
